<template lang="html">
  <v-container pa-0 fluid ref="container">
    <v-row dense>
      <v-col cols="12">
        <v-patient-bar v-model="currentHn" @patientLoaded="onPatientLoaded" @patientUnloaded="onPatientUnloaded"></v-patient-bar>
      </v-col>
      <v-col cols="12" v-if="currentPatient">
        <v-tabs-group key="individual">
          <template v-slot:tabs>
            <v-tab key="opd">ตรวจผู้ป่วยนอก</v-tab>
            <v-tab key="ipd">Admission</v-tab>
            <v-tab key="insurance">สิทธิ์การรักษา</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="opd">
              <v-new-encounter :hn="currentPatient.hn"></v-new-encounter>
            </v-tab-item>
            <v-tab-item key="ipd">
              <v-new-admission :hn="currentPatient.hn"></v-new-admission>
            </v-tab-item>
            <v-tab-item key="insurance">
              <v-insurance-register :hn="currentPatient.hn"></v-insurance-register>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
      <v-col cols="12" v-else>
        <v-tabs-group key="overall">
          <template v-slot:tabs>
            <v-tab key="appointment">รายการนัดหมายวันนี้</v-tab>
            <v-tab key="opd">รายการลงทะเบียนผู้ป่วยนอก</v-tab>
            <v-tab key="ipd">รายการผู้ป่วย Admission</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="appointment">
              <v-model-table model-name="Appointments" model-title="รายการนัดหมาย"
                :model-api="['models','Appointment','Appointments']"
                :model-api-search="appointmentQuery"
                api-with="patient"
                :headers="appointmentHeaders" :insertable="false" expandable>
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.appointmentDateTime="props">
                  <v-label-datetime :date-time="props.item.appointmentDateTime" short-date-time></v-label-datetime>
                </template>
                <template v-slot:item.appointmentType="props">
                  <v-label-master group-key="appointmentType" :item-code="props.item.appointmentType"></v-label-master>
                </template>
                <template v-slot:item.appointmentActivity="props">
                  <v-label-master group-key="appointmentActivity" :item-code="props.item.appointmentActivity"></v-label-master>
                </template>
                <template v-slot:item.showStatus="props">
                  <v-icon color="green darken-3"  v-if="props.item.to_encounter && props.item.to_encounter.length>0">mdi-check-circle-outline</v-icon>
                </template>
                <template v-slot:item.action="props">
                  <v-btn color="primary" @click="currentHn=props.item.hn">เปิด</v-btn>
                </template>
                <template v-slot:expanded-item="{ headers,item,actions }">
                  <td :colspan="headers.length" class="py-0">
                    <v-row>
                      <v-col cols="12" sm="4" md="2" class="font-weight-bold">คำแนะนำ</v-col>
                      <v-col cols="12" sm="8" md="10" style="white-space: pre-line;">{{ item.suggestion || "-" }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4" md="2" class="font-weight-bold">บันทึกภายใน</v-col>
                      <v-col cols="12" sm="8" md="10" style="white-space: pre-line;">{{ item.note || "-" }}</v-col>
                    </v-row>
                  </td>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="opd">
              <v-model-table model-name="Encounters" model-title="รายการลงทะเบียนวันนี้"
                :model-api="['models','Registration','Encounters']" model-key="encounterId"
                :model-api-search="opdEncounterQuery"
                :headers="headers"
                :insertable="false"
              >
                <template v-slot:item.encounterId="props">
                  <v-icon v-if="props.item.fromAppointmentId" color="green darken-3">mdi-calendar-check</v-icon>
                  <v-icon v-else color="red">mdi-calendar-remove</v-icon>
                  <label class="font-weight-bold">{{ props.item.encounterId.slice(-3) }}</label>
                  <v-tooltip v-if="props.item.dischargeDateTime">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="green darken-3">mdi-exit-run</v-icon>
                    </template>
                    <v-label-datetime :date-time="props.item.dischargeDateTime" short-date-time></v-label-datetime>
                  </v-tooltip>
                </template>
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.admitDateTime="props">
                  <v-label-datetime :date-time="props.item.admitDateTime" short-date-time></v-label-datetime>
                </template>
                <template v-slot:item.locationSubunitCode="props">
                  <v-label-items :items="props.item.location.subunits" item-key="subunitCode" item-value="subunitName" :value="props.item.locationSubunitCode"></v-label-items>
                </template>
                <template v-slot:item.action="props">
                  <v-btn color="primary" @click="currentHn=props.item.hn">เปิด</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="ipd">
              <v-model-table model-name="Encounters" model-title="Current admission"
                :model-api="['models','Registration','Encounters']" model-key="encounterId"
                :model-api-search="ipdEncounterQuery" 
                :headers="ipdHeaders"
                :insertable="false"
              >
                <template v-slot:item.admitDateTime="props">
                  <v-label-datetime :date-time="props.item.admitDateTime" short-date-time></v-label-datetime>
                </template>
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.locationSubunitCode="props">
                  <v-label-items :items="props.item.location.subunits" item-key="subunitCode" item-value="subunitName" :value="props.item.locationSubunitCode"></v-label-items>
                </template>
                <template v-slot:item.action="props">
                  <v-btn color="primary" @click="currentHn=props.item.hn">เปิด</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helperDateTime from 'tantee-common/lib/helperDateTime'
import patientLoader from '@/components/mixins/patientLoader'

export default {
  mixins: [patientLoader],
  data: ()=>({
    currentHn: undefined,
    headers: [
      {
        text: 'เลขรับบริการ',
        value: 'encounterId',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'เวลาเข้าตรวจ',
        value: 'admitDateTime',
        class: 'body-2'
      },
      {
        text: 'สถานที่',
        value: 'location.locationName',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'คลินิก',
        value: 'clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'ห้องตรวจ',
        value: 'locationSubunitCode',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '200px',
        sortable: false,
        class: 'body-2'
      }
    ],
    ipdHeaders: [
      {
        text: 'เวลา Admit',
        value: 'admitDateTime',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'Ward',
        value: 'location.locationName',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'ห้อง',
        value: 'locationSubunitCode',
        class: 'body-2'
      },
      {
        text: 'ชนิดเตียง',
        value: 'clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    appointmentHeaders: [
      {
        text: '',
        value: 'showStatus',
        class: 'body-2',
        width: '50px',
      },
      {
        text: 'เวลานัดหมาย',
        value: 'appointmentDateTime',
        class: 'body-2',
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'คลินิก',
        value: 'clinic.clinicName',
        class: 'body-2',
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'ชนิดการนัด',
        value: 'appointmentType',
        class: 'body-2'
      },
      {
        text: 'กิจกรรม',
        value: 'appointmentActivity',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  computed: {
    appointmentQuery() {
      let result = {
        'whereDate#appointmentDateTime': helperDateTime.today().format('YYYY-MM-DD')
      }
      return result
    },
    opdEncounterQuery() {
      let result = {
        encounterType: 'AMB',
        'whereDate#admitDateTime': helperDateTime.today().format('YYYY-MM-DD')
      }
      return result
    },
    ipdEncounterQuery() {
      let result = {
        hn: this.hn,
        encounterType: 'IMP',
        'whereNull#dischargeDateTime': null
      }
      return result
    },
  },
}
</script>

<style lang="css" scoped>
</style>
